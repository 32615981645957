import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dev-modal-content',
  template: `
    <div class="modal-header">
        <h5 class="modal-title text-center">{{headLine}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{modalText}}</div>
    <div class="modal-footer">
        <div class="text-center">
            <button type="button" ngbAutofocus class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">close</button>
        </div>
    </div>
    `
})
export class DevModalContentComponent {
  @Input() name;
  @Input() modalText;
  @Input() headLine;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-dev-modal',
  templateUrl: './dev-modal.component.html',
  styleUrls: ['./dev-modal.component.scss']
})
export class DevModalComponent {
  private modalText: string;
  private headLine: string;

  constructor(private modalService: NgbModal) {}

  @Input('headline') set head(val) {
    this.headLine = val;
  }
  @Input('modalText') set setText(val) {
    this.modalText = val;
  }

  open() {
    const modalRef = this.modalService.open(DevModalContentComponent);
    modalRef.componentInstance.name = 'Development';
    modalRef.componentInstance.headLine = this.headLine;
    modalRef.componentInstance.modalText = this.modalText;
  }
}
