import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { ComponentsComponent } from './components.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { DevModalComponent, DevModalContentComponent } from './dev-modal/dev-modal.component';
import { ImprintComponent } from './imprint/imprint.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import {ParallaxDirective} from '../shared/directives/parallax.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        AngularSvgIconModule,
        HttpClientModule
    ],
    declarations: [
        ComponentsComponent,
        AboutmeComponent,
        DevModalComponent,
        DevModalContentComponent,
        ImprintComponent,
        ParallaxDirective
    ],
    entryComponents: [
        DevModalContentComponent
    ],
    exports: [ ComponentsComponent ]
})
export class ComponentsModule { }
