import { Component, OnInit } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.scss']
})
export class AboutmeComponent implements OnInit {

  constructor(private iconReg: SvgIconRegistryService) { }

  ngOnInit() {
    this.loadIcons();
  }

  private loadIcons() {
    // top skills
    this.iconReg.loadSvg('../../../assets/icons/java.svg', 'java');
    this.iconReg.loadSvg('../../../assets/icons/kotlin.svg', 'kotlin');
    this.iconReg.loadSvg('../../../assets/icons/typescript.svg', 'typescript');
    this.iconReg.loadSvg('../../../assets/icons/php.svg', 'php');
    this.iconReg.loadSvg('../../../assets/icons/spring.svg', 'spring');
    this.iconReg.loadSvg('../../../assets/icons/angular_solidBlack.svg', 'angular');
    this.iconReg.loadSvg('../../../assets/icons/react.svg', 'react');
    this.iconReg.loadSvg('../../../assets/icons/symfony.svg', 'symfony');
    this.iconReg.loadSvg('../../../assets/icons/Git-Icon-Black.eps', 'git');
    this.iconReg.loadSvg('../../../assets/icons/intellij.svg', 'intellij');
    this.iconReg.loadSvg('../../../assets/icons/tux.svg', 'linux');
    this.iconReg.loadSvg('../../../assets/icons/scrum.svg', 'scrum');
    // good knowledge 1
    this.iconReg.loadSvg('../../../assets/icons/javascript-2.svg', 'javascript');
    this.iconReg.loadSvg('../../../assets/icons/go.svg', 'go');
    this.iconReg.loadSvg('../../../assets/icons/c_sharp.svg', 'c');
    this.iconReg.loadSvg('../../../assets/icons/css.svg', 'css');
    this.iconReg.loadSvg('../../../assets/icons/dotnet.svg', 'dotnet');
    this.iconReg.loadSvg('../../../assets/icons/laravel.svg', 'laravel');
    this.iconReg.loadSvg('../../../assets/icons/rest-api.svg', 'rest');
    this.iconReg.loadSvg('../../../assets/icons/docker.svg', 'docker');
    this.iconReg.loadSvg('../../../assets/icons/cloud_native.svg', 'cloud');
    this.iconReg.loadSvg('../../../assets/icons/microservice.svg', 'microservices');
    this.iconReg.loadSvg('../../../assets/icons/kubernetes.svg', 'kubernetes');
    this.iconReg.loadSvg('../../../assets/icons/lambda.svg', 'serverless');
    this.iconReg.loadSvg('../../../assets/icons/azure.svg', 'azure');
    // good knowledge 2
    this.iconReg.loadSvg('../../../assets/icons/nodejs.svg', 'node');
    this.iconReg.loadSvg('../../../assets/icons/sql.svg', 'sql');
    this.iconReg.loadSvg('../../../assets/icons/postgres.svg', 'postgresql');
    this.iconReg.loadSvg('../../../assets/icons/mysql.svg', 'mysql');
    this.iconReg.loadSvg('../../../assets/icons/phpunit.svg', 'phpunit');
    this.iconReg.loadSvg('../../../assets/icons/behat.svg', 'behat');
    this.iconReg.loadSvg('../../../assets/icons/apache.svg', 'apache');
    this.iconReg.loadSvg('../../../assets/icons/nginx.svg', 'nginx');
    this.iconReg.loadSvg('../../../assets/icons/macos.svg', 'macos');
    this.iconReg.loadSvg('../../../assets/icons/kvm.svg', 'kvm');
    this.iconReg.loadSvg('../../../assets/icons/kanban.svg', 'kanban');
    // basic knowledge
    this.iconReg.loadSvg('../../../assets/icons/c.svg', 'c');
    this.iconReg.loadSvg('../../../assets/icons/vuejs.svg', 'vuejs');
    this.iconReg.loadSvg('../../../assets/icons/sass.svg', 'sass');
    this.iconReg.loadSvg('../../../assets/icons/less.svg', 'less');
    this.iconReg.loadSvg('../../../assets/icons/helm.svg', 'helm');
    this.iconReg.loadSvg('../../../assets/icons/vagrant.svg', 'vagrant');
    this.iconReg.loadSvg('../../../assets/icons/karma.svg', 'karma');
    this.iconReg.loadSvg('../../../assets/icons/jasmine.svg', 'jasmine');
  }
}
